/* import __COLOCATED_TEMPLATE__ from './top-slot.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {}

interface Signature {
  Element: HTMLElement;
  Args: Args;
  Blocks: any;
}

const TopSlot = templateOnlyComponent<Signature>();
export default TopSlot;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Layout::Module::TopSlot': typeof TopSlot;
    'layout/module/top-slot': typeof TopSlot;
  }
}
