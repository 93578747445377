/* import __COLOCATED_TEMPLATE__ from './overview.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type Invokable } from '@glint/template/-private/integration';
import { type ViewId } from 'embercom/components/knowledge-hub/setup-modal';
import { EntityType } from 'embercom/models/data/entity-types';
import { type KnowledgeUsageSummary, type SyncSourceType } from './overview/types';
import Conversations from './overview/sections/conversations';
import InternalArticles from './overview/sections/internal-articles';
import MoreSources from './overview/sections/more-sources';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import PublicArticles from './overview/sections/public-articles';
import Websites from './overview/sections/websites';
import type Store from '@ember-data/store';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import Macros from './overview/sections/macros';
import ConversationExtraction from './overview/sections/conversation-extraction';
import type ConversationExtractionService from 'embercom/services/conversation-extraction-service';
import { type Action } from 'embercom/components/common/hero-banner';

export interface OverviewArgs {
  activeTab?: ContentFilter | undefined;
  showManageConversationsDrawer?: boolean;
  showManageMacrosModal?: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: OverviewArgs;
}

export enum ContentFilter {
  All = 'all',
  AiAgent = 'ai-agent',
  AiCopilot = 'ai-copilot',
  HelpCenter = 'help-center',
}

export const VALID_CONTENT_FILTERS = {
  [ContentFilter.All]: true,
  [ContentFilter.AiAgent]: true,
  [ContentFilter.AiCopilot]: true,
  [ContentFilter.HelpCenter]: true,
};

export const TEST_AI_AGENT_ROUTE = 'apps.app.automation.fin-ai-agent.setup';
export const TEST_AI_COPILOT_ROUTE = 'apps.app.inbox';

const SOURCE_TYPE_TO_ENTITY_TYPE: Record<SyncSourceType | 'file-upload', EntityType> = {
  guru: EntityType.InternalArticle,
  confluence: EntityType.InternalArticle,
  notion: EntityType.InternalArticle,
  zendesk: EntityType.ArticleContent,
  external_content: EntityType.ExternalContent,
  zendesk_tickets: EntityType.Conversation,
  'file-upload': EntityType.FileSourceContent,
  salesforce_knowledge: EntityType.ExternalContent,
  salesforce_cases: EntityType.Conversation,
  box: EntityType.ExternalContent,
};

export interface OverviewSectionArgs {
  knowledgeUsageSummary: KnowledgeUsageSummary;
  contentFilter: ContentFilter;
  onAddSource: (sourceType: SyncSourceType | 'file-upload') => void;
  showManageConversationsDrawer?: boolean;
  showManageMacrosModal?: boolean;
}

export default class KnowledgeHubOverview extends Component<Signature> {
  @service declare appService: any;
  @service declare router: any;
  @service declare intl: any;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare conversationExtractionService: ConversationExtractionService;
  @service declare realTimeEventService: { on: Function; off: Function; subscribeTopics: Function };
  @service declare intercomEventService: { trackAnalyticsEvent: Function };
  @service declare helpCenterService: $TSFixMe;
  @service declare store: Store;

  @tracked showSetupModal = false;
  @tracked setupModalEntryPoint?: ViewId = undefined;
  @tracked selectedEntityType: EntityType | null = null;
  @tracked contentFilter: ContentFilter = ContentFilter.All;
  @tracked sectionsConfigs: {
    component: Invokable<any>;
    visibleFor: ContentFilter[];
  }[];
  @tracked showCreateCollectionModal = false;

  public constructor(owner: any, args: any) {
    super(owner, args);
    if (this.args.activeTab && VALID_CONTENT_FILTERS[this.args.activeTab]) {
      this.contentFilter = this.args.activeTab;
    }
    if (this.appService.app.canUseStandalone) {
      this.contentFilter = ContentFilter.AiAgent;
    }
    this.knowledgeHubService.subscribeToSourceSyncEvents();
    this.sectionsConfigs = [
      {
        component: PublicArticles,
        visibleFor: [ContentFilter.All, ContentFilter.AiAgent, ContentFilter.HelpCenter],
      },
      {
        component: InternalArticles,
        visibleFor: [ContentFilter.All, ContentFilter.AiCopilot],
      },
      {
        component: Conversations,
        visibleFor: [ContentFilter.All, ContentFilter.AiCopilot],
      },
      {
        component: Macros,
        visibleFor: [ContentFilter.All, ContentFilter.AiCopilot],
      },
      {
        component: PublicArticles,
        visibleFor: [ContentFilter.AiCopilot],
      },
      {
        component: Websites,
        visibleFor: [ContentFilter.All, ContentFilter.AiCopilot, ContentFilter.AiAgent],
      },
      {
        component: MoreSources,
        visibleFor: [ContentFilter.All, ContentFilter.AiCopilot, ContentFilter.AiAgent],
      },
    ];

    if (this.shouldUseConversationExtraction) {
      this.sectionsConfigs.unshift({
        component: ConversationExtraction,
        visibleFor: [ContentFilter.All, ContentFilter.AiAgent, ContentFilter.AiCopilot],
      });
      this.conversationExtractionService.subscribeToImportProgressEvents();
    }
  }

  willDestroy() {
    super.willDestroy();
    this.knowledgeHubService.unsubscribeToSourceSyncEvents();
    if (this.shouldUseConversationExtraction) {
      this.conversationExtractionService.unsubscribeFromImportProgressEvents();
    }
  }

  get shouldUseConversationExtraction() {
    return (
      !this.appService.app.canUseStandalone || this.appService.app.canUseExtractionExternalSources
    );
  }

  get usageSummary(): KnowledgeUsageSummary {
    return this.knowledgeHubService.usageSummary || {};
  }

  @action openSetupModalForEntity(entityType: EntityType) {
    this.showSetupModal = true;
    this.selectedEntityType = entityType;
  }

  @action changeTab(filter: ContentFilter) {
    this.contentFilter = filter;
    this.scrollToTop();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'tab',
      section: 'knowledge_hub',
      place: 'sources',
      tab: filter,
    });
  }

  private scrollToTop() {
    document.querySelector('.overview__main-container')?.scrollTo({ top: 0, behavior: 'smooth' });
  }

  @action async closeSetupModal() {
    this.showSetupModal = false;
    this.selectedEntityType = null;
    this.setupModalEntryPoint = undefined;
    await this.knowledgeHubService.removeEmptySources();
    this.knowledgeHubService.onSummaryChange();
  }

  get newHeroBannerTitle(): string {
    return this.appService.app.canShowFinNuxSAPages && this.appService.app.canUseStandalone
      ? 'knowledge-hub.new-banner.standalone-title'
      : 'knowledge-hub.new-banner.title';
  }

  get newHeroBannerDescription(): string {
    return this.appService.app.canShowFinNuxSAPages && this.appService.app.canUseStandalone
      ? 'knowledge-hub.new-banner.standalone-description'
      : 'knowledge-hub.new-banner.description';
  }

  get newHeroBannerActions(): Action[] {
    if (this.appService.app.canShowFinNuxSAPages && this.appService.app.canUseStandalone) {
      return [
        {
          type: 'link',
          label: 'knowledge-hub.new-banner.actions.article-optimizing-content-for-fin',
          icon: 'article',
          analyticsId: 'optimizing-content-for-fin-article',
          onClick: () => {
            window.Intercom('showArticle', 7860255); // https://www.intercom.com/help/en/articles/7860255-support-content-best-practices-for-fin
          },
        },
        {
          type: 'link',
          label: 'knowledge-hub.new-banner.actions.overview-content-types',
          icon: 'article',
          analyticsId: 'overview-content-types',
          onClick: () => {
            window.Intercom('showArticle', 9357928); // https://www.intercom.com/help/en/articles/9357928-overview-of-content-types-and-when-to-use-them
          },
        },
      ];
    }
    return [
      {
        type: 'link',
        label: 'knowledge-hub.new-banner.actions.article-knowledge-explained',
        icon: 'article',
        analyticsId: 'knowledge-explained-article',
        onClick: () => {
          window.Intercom('showArticle', 9357912); // https://www.intercom.com/help/en/articles/9357912-knowledge-hub-explained
        },
      },
      {
        type: 'link',
        label: 'knowledge-hub.new-banner.actions.article-help-center-explained',
        icon: 'article',
        analyticsId: 'help-center-explained-article',
        onClick: () => {
          window.Intercom('showArticle', 56640); // https://www.intercom.com/help/en/articles/56640-help-center-explained
        },
      },
      {
        type: 'link',
        label: 'knowledge-hub.new-banner.actions.article-optimizing-content-for-fin',
        icon: 'article',
        analyticsId: 'optimizing-content-for-fin-article',
        onClick: () => {
          window.Intercom('showArticle', 7860255); // https://www.intercom.com/help/en/articles/7860255-support-content-best-practices-for-fin
        },
      },
      {
        type: 'link',
        label: 'knowledge-hub.new-banner.actions.video-intro-to-knowledge-management',
        icon: 'video',
        analyticsId: 'intro-to-knowledge-management-video',
        onClick: () => {
          safeWindowOpen(
            'https://www.youtube.com/watch?v=uD0f9BbpTCQ&list=PLlCIldMZCaFrwsQvjSIRXYS35CqEFKbFX',
            '_blank',
          );
        },
      },
    ];
  }

  get testFinOptions() {
    return [
      {
        items: [
          {
            text: this.intl.t('knowledge-hub.overview.test-fin-button.options.test-ai-agent'),
            onSelectItem: () => this.openFinAgentPage(),
          },
          {
            text: this.intl.t('knowledge-hub.overview.test-fin-button.options.test-ai-copilot'),
            onSelectItem: () => this.openFinCopilotPage(),
          },
        ],
      },
    ];
  }

  openFinAgentPage() {
    let agentUrl = this.router.urlFor(TEST_AI_AGENT_ROUTE);
    safeWindowOpen(agentUrl);
  }

  openFinCopilotPage() {
    let copilotUrl = this.router.urlFor(
      TEST_AI_COPILOT_ROUTE,
      { app_id: this.appService.app.id },
      {
        queryParams: { open_copilot: true },
      },
    );
    safeWindowOpen(copilotUrl);
  }

  get sectionComponents() {
    return this.sectionsConfigs.filter((section) =>
      section.visibleFor.includes(this.contentFilter),
    );
  }

  @action async showModal(syncSourceType: SyncSourceType | 'file-upload') {
    try {
      let entityType = SOURCE_TYPE_TO_ENTITY_TYPE[syncSourceType];
      await this.knowledgeHubService.ensurePermissions(entityType);
    } catch (error) {
      return;
    }
    switch (syncSourceType) {
      case 'guru':
        this.setupModalEntryPoint = 'guru-sync';
        break;
      case 'confluence':
        this.setupModalEntryPoint = 'confluence-sync';
        break;
      case 'notion':
        this.setupModalEntryPoint = 'notion-sync';
        break;
      case 'zendesk':
        this.setupModalEntryPoint = 'zendesk-sync';
        break;
      case 'external_content':
        this.setupModalEntryPoint = 'external-content';
        break;
      case 'file-upload':
        this.setupModalEntryPoint = 'file-upload';
        break;
      case 'salesforce_knowledge':
        this.setupModalEntryPoint = 'salesforce-sync';
        break;
      case 'box':
        this.setupModalEntryPoint = 'box-sync';
        break;
      default:
        return;
    }
    this.showSetupModal = true;
  }

  get contentFilterIsValidForChecklist(): boolean {
    return this.contentFilter !== ContentFilter.All;
  }

  @action updateOverviewChecklistAttributes() {
    this.knowledgeHubService.getOverviewChecklistAttributes();
  }

  get copilotFeatureAvailable(): boolean {
    return this.appService.app.canUseFeature('answerbot-inbox-copilot');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Overview': typeof KnowledgeHubOverview;
  }
}
