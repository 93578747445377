/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Model from '@ember-data/model';
import { type LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import ArticlesComposerConfig from 'embercom/lib/articles/composer-config';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';
import { type ContentVersion } from 'embercom/services/knowledge-hub-editor-service';
import {
  copyHeadingLinkToClipboard,
  getSelectionForAI,
} from 'embercom/helpers/articles/editor-helper';
import type ArticleContent from 'embercom/models/articles/article-content';
import type ClipboardService from 'embercom/services/clipboard-service';
import type IntlService from 'ember-intl/services/intl';
import { EntityType } from 'embercom/models/data/entity-types';
import ENV from 'embercom/config/environment';
import { action } from '@ember/object';
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import { tracked } from '@glimmer/tracking';
import type Composer from '@intercom/embercom-prosemirror-composer/lib/composer';
import type ContentSnippet from 'embercom/models/content-service/content-snippet';
import { type Block } from '@intercom/interblocks.ts';

interface Args {
  suggestedVersion: ContentVersion;
  latestVersion?: ContentVersion;
  activeContent: LocalizedKnowledgeContent & Model;
  isEditReviewType: boolean;
  showEditComposer: boolean;
  updateSuggestionBlocks: (blocks: Array<Block>) => void;
}

export type ReviewableContent = ArticleContent | ContentSnippet;

export default class Editor extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare clipboardService: ClipboardService;
  @service declare intercomEventService: $TSFixMe;

  @tracked declare composer: Composer;

  constructor(owner: any, args: Args) {
    super(owner, args);

    if (args.showEditComposer) {
      this.args.updateSuggestionBlocks(args.suggestedVersion.jsonBlocks);
    }
  }

  get app() {
    return this.appService.app;
  }

  get activeContent() {
    return this.args.activeContent;
  }

  get isArticleContent() {
    return this.activeContent.entityType === EntityType.ArticleContent;
  }

  get isContentSnippet() {
    return this.activeContent.entityType === EntityType.ContentSnippet;
  }

  get contentSnippet() {
    return this.activeContent as ContentSnippet;
  }

  get showVersionDiff() {
    return this.args.isEditReviewType;
  }

  get blocksDocument() {
    return new BlocksDocument(this.args.suggestedVersion.jsonBlocks);
  }

  get contentReviewRequest() {
    return (this.activeContent as ReviewableContent).contentReviewRequest;
  }

  @action
  articleContentComposerReady(composer: Composer) {
    this.composer = composer;
  }

  @action
  composerChanged(newBlocksDocument: BlocksDocument) {
    this.args.updateSuggestionBlocks(newBlocksDocument.blocks);
  }

  get articleContentComposerConfig() {
    let articleContent = this.activeContent as unknown as ArticleContent;
    let anchorLinkOnClick = (heading: any) => {
      copyHeadingLinkToClipboard(
        heading,
        articleContent,
        this.notificationsService,
        this.intl,
        this.clipboardService,
        this.intercomEventService,
        this.activeContent.locale ?? '',
      );
    };
    let config = new ArticlesComposerConfig(
      this.app,
      articleContent.textDirection,
      this.analytics('article'),
      anchorLinkOnClick,
      true,
    );
    if (this.app.canUseArticlesAi) {
      config.formatters = {
        placement: 'right',
        config: [{ icon: 'ai', key: 'ai-expand' }].map(({ icon, key }) => ({
          icon,
          name: key,
          transform: async (selectedBlocks: any) => {
            this.notificationsService.notify(
              this.intl.t('articles.editor.ai-running'),
              ENV.APP._3000MS,
            );
            let blocks = await getSelectionForAI(
              selectedBlocks,
              this.app,
              articleContent.id,
              this.notificationsService,
            );
            return blocks;
          },
          description: this.intl.t(`articles.editor.${key}`),
        })),
      };
    }
    return config;
  }

  private analytics(object: string) {
    return {
      trackAnalyticsEvent: (eventName: any, attrs: any) => {
        this.intercomEventService.trackAnalyticsEvent({
          action: eventName,
          object,
          place: 'composer',
          ...attrs,
        });
      },
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::ContentReview::Editor': typeof Editor;
    'knowledge-hub/content-editor/content-review/editor': typeof Editor;
  }
}
