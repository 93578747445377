/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { restartableTask } from 'ember-concurrency-decorators';
import type KnowledgeHubApi from 'embercom/lib/knowledge-hub/list-api';
import { type TaskGenerator } from 'ember-concurrency';
import { type SortDirection } from 'embercom/lib/knowledge-hub/constants';
import { taskFor } from 'ember-concurrency-ts';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { type Tree } from 'embercom/objects/tree-list';
import type KnowledgeHubContentWrapper from 'embercom/models/content-service/knowledge-hub-content-wrapper';
import type Router from '@ember/routing/router-service';
import { createTreeItem } from '../folders/tree-helper';
import { type ColumnData } from 'embercom/lib/ai-content-library/constants';
import type ContentFragment from 'embercom/models/content-service/content-fragment';
import type AiContentSegmentsService from 'embercom/services/ai-content-segments-service';

interface Args {
  columns: TableColumn[];
  listApi: KnowledgeHubApi;
  sortDirection?: SortDirection;
  sortBy?: string;
  setSortBy: (sortBy?: string) => void;
  setSortDirection: (sortDirection?: string) => void;
  showAllTranslations: boolean;
  toggleTranslations: () => void;
  selectableColumns: ColumnData[];
  updatedSelectedTableColumns: (columns: string[]) => void;
  showEmptyState: boolean;
  resetFilters: () => void;
  hasAppliedFilter: boolean;
  selectedFragments: Set<ContentFragment>;
  setSelectedFragments: (wrappers: Set<ContentFragment>) => void;
  unselectedFragments: Set<ContentFragment>;
  setUnselectedFragments: (wrappers: Set<ContentFragment>) => void;
  isBulkSelection: boolean;
  setIsBulkSelection: (isBulkSelection: boolean) => void;
  resetSelectedFragments: () => void;
}

export interface TableColumn {
  valuePath: string;
  type?: string;
  isDisabled?: boolean;
  labelIntlKey?: string;
  tooltipIntlKey?: string;
  label?: string;
  tooltip?: string;
  isVisible?: boolean;
  isSortable?: boolean;
  customHeaderComponent?: string;
}

const BULK_UPDATE_MAX = 100;

export default class List extends Component<Args> {
  @service declare appService: any;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare aiContentSegmentsService: AiContentSegmentsService;
  @service declare router: Router;

  @tracked sortDirection = this.args.sortDirection;
  @tracked sortBy = this.args.sortBy;
  @tracked tree: Tree;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    // set tree
    this.tree = this.knowledgeHubService.tree;
    if (!this.aiContentSegmentsService.hasLoadedSegments) {
      this.aiContentSegmentsService.loadSegments();
    }
  }

  get isLoading(): boolean {
    return this.args.listApi.isLoadingPage;
  }

  get canLoadMore(): boolean {
    return !this.args.listApi.lastPageHit;
  }

  get processedColumns() {
    if (!this.args.columns) {
      return [];
    }

    let columns: TableColumn[] = this.args.columns;

    let localeColumn = columns.find((col) => col.valuePath === 'locale');
    if (localeColumn) {
      localeColumn.isVisible = true;
    }
    return columns;
  }

  get showTranslationsToggle(): boolean {
    return this.knowledgeHubService.availableLocaleIds.length > 1;
  }

  @action
  resetFiltersAndContentSelection() {
    this.args.resetFilters();
    this.args.resetSelectedFragments();
  }

  @action
  loadTreeItems() {
    this.args.listApi.contentWrappers.forEach((contentWrapper: KnowledgeHubContentWrapper) => {
      let parent = contentWrapper.parent?.treeItem || this.tree;
      let treeItem = createTreeItem(parent, contentWrapper, false, this.removeContentWrapper);
      contentWrapper.treeItem = treeItem;
    });
  }

  get rowsWithTreeItems() {
    this.loadTreeItems();
    return this.args.listApi.contentWrappers as KnowledgeHubContentWrapper[];
  }

  @action
  removeContentWrapper(contentWrapperId: string) {
    return this.knowledgeHubService.api?.removeContentWrapper(contentWrapperId);
  }

  @action
  onBulkSelectAction() {
    let isBulkSelection = !this.args.isBulkSelection;
    this.args.setIsBulkSelection(isBulkSelection);
    let allContentWrappers = isBulkSelection
      ? [...(this.args.listApi.contentWrappers as KnowledgeHubContentWrapper[])]
      : [];
    let allContentFragments: ContentFragment[] = allContentWrappers.map(
      (contentWrapper) => contentWrapper.defaultContent,
    );
    this.args.setSelectedFragments(new Set(allContentFragments));
    this.args.setUnselectedFragments(new Set());
  }

  get selectedCount(): number {
    if (this.args.isBulkSelection) {
      return this.args.listApi.totalCount - this.args.unselectedFragments.size;
    } else {
      return this.args.selectedFragments.size;
    }
  }

  @restartableTask
  *sortUpdate(sortBy: string, sortDirection: SortDirection): TaskGenerator<void> {
    this.sortBy = sortBy;
    this.args.setSortBy(this.sortBy ?? undefined);
    this.sortDirection = sortDirection;
    this.args.setSortDirection(this.sortDirection ?? undefined);
    yield this.args.listApi.sortUpdate(sortBy ?? undefined, sortDirection ?? undefined);
  }

  @action
  onSort(clickedValuePath: string): void {
    let newSortDirection: SortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.sortDirection = newSortDirection;
    taskFor(this.sortUpdate).perform(clickedValuePath, newSortDirection);
  }

  @restartableTask
  *paginate(): TaskGenerator<void> {
    if (!this.args.listApi.isLoadingPage) {
      yield this.args.listApi.loadPage();
    }
  }

  get maxBulkUpdatable(): number {
    return BULK_UPDATE_MAX;
  }

  @restartableTask *reloadPage(): TaskGenerator<void> {
    yield this.args.listApi.search();
    yield this.args.listApi.loadPage();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::List': typeof List;
    'knowledge-hub/filterable-list/list': typeof List;
  }
}
