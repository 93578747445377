/* import __COLOCATED_TEMPLATE__ from './modal.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import type ConversationExtractionService from 'embercom/services/conversation-extraction-service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { type IntegrationWarningReason } from './integration-warning';

interface Args {
  modal: any;
  title?: string;
  onNavigationNext?: (viewId?: string) => void;
  onNavigationBack?: () => void;
  onModalClose?: () => void;
  showBackButton?: boolean;
}

export default class Modal extends Component<Args> {
  @service declare intl: IntlService;
  @service declare notificationsService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service intercomEventService: $TSFixMe;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare conversationExtractionService: ConversationExtractionService;
  @service declare finStandaloneService: FinStandaloneService;

  get conversationExtractionSettings() {
    return this.conversationExtractionService.conversationExtractionSettings;
  }

  get isLatestImportInProgress() {
    return this.conversationExtractionSettings?.isLatestImportInProgress;
  }

  get hasZendeskConnection() {
    return this.finStandaloneService.zendeskConfig?.isConnected;
  }

  get hasSalesforceConnection() {
    return this.finStandaloneService.salesforceConfig?.isConnected;
  }

  get hasZendeskFinUser() {
    return !!this.finStandaloneService.zendeskConfig?.ticketsIntegration?.finZendeskUserId;
  }

  get hasSalesforceFinUser() {
    return !!this.finStandaloneService.salesforceConfig?.finSalesforceUserId;
  }

  get platform(): 'zendesk' | 'salesforce' | null {
    if (this.appService.app.hasStandalonePlatform('zendesk')) {
      return 'zendesk';
    }
    if (this.appService.app.hasStandalonePlatform('salesforce')) {
      return 'salesforce';
    }
    return null;
  }

  get integrationWarning(): IntegrationWarningReason | null {
    switch (this.platform) {
      case 'zendesk':
        return this.zendeskIntegrationWarning;
      case 'salesforce':
        return this.salesforceIntegrationWarning;
      default:
        return null;
    }
  }

  get salesforceIntegrationWarning(): IntegrationWarningReason | null {
    if (!this.hasSalesforceConnection) {
      return 'salesforce-integration';
    }
    if (!this.hasSalesforceFinUser) {
      return 'salesforce-fin-user';
    }
    return null;
  }

  get zendeskIntegrationWarning(): IntegrationWarningReason | null {
    if (!this.hasZendeskConnection) {
      return 'zendesk-tickets-integration';
    }
    if (!this.hasZendeskFinUser) {
      return 'zendesk-fin-user';
    }
    return null;
  }

  get isSettingsSubmitBlocked() {
    return (
      this.conversationExtractionSettings?.isLatestImportInProgress || !!this.integrationWarning
    );
  }

  get settingsBlockedReason() {
    if (this.isLatestImportInProgress) {
      return this.intl.t(
        'knowledge-hub.conversational.conversation-extraction-settings.settings-blocked-reason.import-in-progress',
      );
    }
    if (this.appService.app.hasStandalonePlatform('zendesk')) {
      if (!this.hasZendeskConnection) {
        return this.intl.t(
          'knowledge-hub.conversational.conversation-extraction-settings.standalone.settings-blocked-reason.no-integration.zendesk',
        );
      }

      if (!this.hasZendeskFinUser) {
        return this.intl.t(
          'knowledge-hub.conversational.conversation-extraction-settings.standalone.settings-blocked-reason.no-fin-id',
        );
      }
    } else if (this.appService.app.hasStandalonePlatform('salesforce')) {
      if (!this.hasSalesforceConnection) {
        return this.intl.t(
          'knowledge-hub.conversational.conversation-extraction-settings.standalone.settings-blocked-reason.no-integration.salesforce',
        );
      }

      if (!this.hasSalesforceFinUser) {
        return this.intl.t(
          'knowledge-hub.conversational.conversation-extraction-settings.standalone.settings-blocked-reason.no-fin-id',
        );
      }
    }
    return '';
  }

  @action async submitSettings() {
    if (!this.conversationExtractionSettings || this.isSettingsSubmitBlocked) {
      return;
    }

    await this.knowledgeHubService.ensurePermissions();

    try {
      this.conversationExtractionSettings.enabled = true;
      await this.conversationExtractionSettings.save();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'saved',
        object: 'conversation_extraction_settings',
        enabled: true,
        admin_ids: [],
        team_ids: [],
        default_chatbot_availability:
          this.conversationExtractionSettings?.defaultChatbotAvailability,
        default_copilot_availability:
          this.conversationExtractionSettings?.defaultCopilotAvailability,
      });
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'knowledge-hub.conversational.conversation-extraction-settings.update-notifications.success',
        ),
      );
      if (this.args.onModalClose) {
        this.args.onModalClose();
      }
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: this.intl.t(
          'knowledge-hub.conversational.conversation-extraction-settings.update-notifications.failure',
        ),
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Conversational::Standalone::Modal': typeof Modal;
  }
}
