/* import __COLOCATED_TEMPLATE__ from './right-slot.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {}

interface Signature {
  Element: HTMLElement;
  Args: Args;
  Blocks: any;
}

const RightSlot = templateOnlyComponent<Signature>();
export default RightSlot;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Layout::Module::RightSlot': typeof RightSlot;
    'layout/module/right-slot': typeof RightSlot;
  }
}
