/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';
import { action } from '@ember/object';
import { schedule } from '@ember/runloop';
import type Model from '@ember-data/model';
import { type LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import type IntlService from 'ember-intl/services/intl';
import { tracked } from '@glimmer/tracking';
import type { ReviewableContent } from './editor';
import { ReviewType } from 'embercom/models/content-service/content-review-request';
import { objectNames } from 'embercom/models/data/matching-system/matching-constants';

interface Args {
  showSidePanel: boolean;
  toggleSidePanel: () => void;
  activeContent: LocalizedKnowledgeContent & Model;
  setEditMode: (show: boolean) => void;
  approveVersion: () => void;
  rejectVersion: () => void;
  disableButtons: boolean;
}

export default class Header extends Component<Args> {
  closeSidePanelOnResize = true;

  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;
  @service declare intl: IntlService;

  @tracked editingContent = false;

  get title() {
    let content = this.args.activeContent;
    let objectName = objectNames[content.entityType];
    let reviewType = (content as ReviewableContent).contentReviewRequest.get('reviewType');

    if (reviewType === ReviewType.CREATE_CONTENT) {
      return this.intl.t(`knowledge-hub.content-editor.drawer-editor.titles.add-${objectName}`);
    } else if (reviewType === ReviewType.EDIT_CONTENT) {
      return this.intl.t(`knowledge-hub.content-editor.drawer-editor.titles.edit-${objectName}`);
    } else if (reviewType === ReviewType.DELETE_CONTENT) {
      return this.intl.t(`knowledge-hub.content-editor.drawer-editor.titles.delete-${objectName}`);
    }
    return this.intl.t(
      `knowledge-hub.content-editor.drawer-editor.titles.fallback-title-${objectName}`,
    );
  }

  @action closeSidePanel(header: HTMLElement) {
    let headerHasScroll = header.offsetWidth < header.scrollWidth;
    let closeSidePanel = headerHasScroll && this.closeSidePanelOnResize && this.args.showSidePanel;
    this.closeSidePanelOnResize = !headerHasScroll && this.args.showSidePanel;
    if (closeSidePanel) {
      // schedule the side panel to close after it finishes opening, to avoid a race condition
      schedule('afterRender', () => {
        this.args.toggleSidePanel();
      });
    }
  }

  @action
  switchToEdit() {
    this.editingContent = true;
    this.args.setEditMode(true);
  }

  @action
  switchToView() {
    this.editingContent = false;
    this.args.setEditMode(false);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::ContentReview::Header': typeof Header;
    'knowledge-hub/content-editor/content-review/header': typeof Header;
  }
}
