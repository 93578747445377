/* import __COLOCATED_TEMPLATE__ from './container.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  class?: string;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
  Blocks: any;
}

const Container = templateOnlyComponent<Signature>();
export default Container;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Layout::Module::Container': typeof Container;
    'layout/module/container': typeof Container;
  }
}
