/* import __COLOCATED_TEMPLATE__ from './setup-modal.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import GuruSyncModal from './setup-modal/sync-modal/guru';
import ConfluenceSyncModal from './setup-modal/sync-modal/confluence';
import NotionSyncModal from './setup-modal/sync-modal/notion';
import BoxSyncModal from './setup-modal/sync-modal/box';
import ZendeskSyncModal from './setup-modal/sync-modal/zendesk';
import SelectContentType from './filterable-list/setup-modal/select-content-type';
import FileUploadModal from '../content-service/ai-content-library/file-upload-modal';
import PastConversationModal from '../content-service/ai-content-library/past-conversation-modal';
import Modal from './conversational/settings/modal';
import CollectionPickerModalContents from './content-editor/shared/collection-picker-modal-contents';
import CollectionEditorModalContents from './content-editor/shared/collection-editor-modal-contents';
import SetUpHelpCenterFirstModal from './content-editor/shared/set-up-help-center-first-modal';
import SalesforceSyncModal from './setup-modal/sync-modal/salesforce';
import StandaloneAddKnowledgeModal from '../standalone/channels/standalone-add-knowledge-modal';

interface Args {
  onModalClose: () => void;
  entrypoint?: ViewId;
  context: any;
}

export type ViewId =
  | 'select-content'
  | 'set-up-help-center'
  | 'collection-picker'
  | 'collection-editor'
  | 'guru-sync'
  | 'confluence-sync'
  | 'notion-sync'
  | 'zendesk-sync'
  | 'salesforce-sync'
  | 'box-sync'
  | 'file-upload'
  | 'external-content'
  | 'conversation-extraction'
  | 'past-conversation'
  | 'standalone-add-knowledge';

type View = {
  title: string;
  component: any;
  id: string;
};

export default class SetupModal extends Component<Args> {
  @tracked currentStepIndex = 0;
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;

  get views(): Record<ViewId, View> {
    return {
      'select-content': {
        id: 'select-content',
        title: this.intl.t('knowledge-hub.filterable-list.setup-modal.views.select-content.title'),
        component: SelectContentType,
      },
      'set-up-help-center': {
        id: 'set-up-help-center',
        title: this.intl.t('articles.collections.set_up_help_center_first_modal.title'),
        component: SetUpHelpCenterFirstModal,
      },
      'collection-picker': {
        id: 'collection-picker',
        title: this.intl.t('articles.collections.collection_picker_modal.title'),
        component: CollectionPickerModalContents,
      },
      'collection-editor': {
        id: 'collection-editor',
        title: this.intl.t(
          'knowledge-hub.content-editor.shared.collection-editor.collection-editor-modal-title',
        ),
        component: CollectionEditorModalContents,
      },
      'file-upload': {
        id: 'file-upload',
        title: this.intl.t('knowledge-hub.filterable-list.setup-modal.views.file-upload.title'),
        component: FileUploadModal,
      },
      'external-content': {
        id: 'external-content',
        title: this.intl.t(
          'knowledge-hub.filterable-list.setup-modal.views.external-content.title',
        ),
        component: 'content-service/ai-content-library/sources/add-source-modal',
      },
      'guru-sync': {
        id: 'guru',
        title: this.intl.t('knowledge-hub.filterable-list.setup-modal.views.guru-sync.title'),
        component: GuruSyncModal,
      },
      'confluence-sync': {
        id: 'confluence',
        title: this.intl.t('knowledge-hub.filterable-list.setup-modal.views.confluence-sync.title'),
        component: ConfluenceSyncModal,
      },
      'notion-sync': {
        id: 'notion',
        title: this.intl.t('knowledge-hub.filterable-list.setup-modal.views.notion-sync.title'),
        component: NotionSyncModal,
      },
      'zendesk-sync': {
        id: 'zendesk',
        title: this.intl.t('knowledge-hub.filterable-list.setup-modal.views.zendesk-sync.title'),
        component: ZendeskSyncModal,
      },
      'salesforce-sync': {
        id: 'salesforce_knowledge',
        title: this.intl.t('knowledge-hub.filterable-list.setup-modal.views.salesforce-sync.title'),
        component: SalesforceSyncModal,
      },
      'box-sync': {
        id: 'box',
        title: this.intl.t('knowledge-hub.filterable-list.setup-modal.views.box-sync.title'),
        component: BoxSyncModal,
      },
      'conversation-extraction': {
        id: 'conversation-extraction',
        title: this.intl.t(
          'knowledge-hub.filterable-list.setup-modal.views.conversation-extraction.title',
        ),
        component: Modal,
      },
      'past-conversation': {
        id: 'past-conversation',
        title: this.intl.t(
          'knowledge-hub.filterable-list.setup-modal.views.past-conversation.title',
        ),
        component: PastConversationModal,
      },
      'standalone-add-knowledge': {
        id: 'standalone-add-knowledge',
        title: this.intl.t('knowledge-hub.filterable-list.setup-modal.views.select-content.title'),
        component: StandaloneAddKnowledgeModal,
      },
    };
  }

  get modalWidth() {
    let isSmallModal = ['set-up-help-center', 'collection-picker', 'collection-editor'].includes(
      this.currentView.id,
    );
    return isSmallModal ? 'm' : 'l';
  }

  @tracked
  navigationStack: View[] = [];

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.setFirstView();
  }

  setFirstView() {
    let entrypoint: ViewId = 'select-content';
    if (this.appService.app.canUseStandalone) {
      entrypoint = 'standalone-add-knowledge';
    }

    if (this.args.entrypoint) {
      entrypoint = this.args.entrypoint;
    }
    this.navigationStack.push(this.views[entrypoint]);
  }

  @action
  stepNext(viewId?: ViewId) {
    if (!viewId) {
      return this.args.onModalClose();
    }
    this.navigationStack.push(this.views[viewId]);
    this.currentStepIndex = this.navigationStack.length - 1;
  }

  @action
  stepBack() {
    this.navigationStack.pop();
    this.currentStepIndex = this.navigationStack.length - 1;
    if (this.currentStepIndex < 0) {
      this.args.onModalClose();
    }
  }

  get currentView() {
    return this.navigationStack[this.currentStepIndex];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::SetupModal': typeof SetupModal;
    'knowledge-hub/setup-modal': typeof SetupModal;
  }
}
