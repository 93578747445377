/* import __COLOCATED_TEMPLATE__ from './option-menu.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import type Folder from 'embercom/models/content-service/folder';
import { type TreeItem } from 'embercom/objects/tree-list';
import type Router from '@ember/routing/router-service';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import { action } from '@ember/object';
import { removeItemFromTree } from './tree-helper';
import { CAN_MANAGE_KNOWLEDGE_BASE_CONTENT } from 'embercom/lib/knowledge-hub/constants';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';

interface Args {
  item: TreeItem<Folder> | undefined;
  folder: Folder | undefined;
  position: string;
  routeAfterDelete: string;
}

export default class OptionMenu extends Component<Args> {
  @service declare appService: any;
  @service declare store: Store;
  @service notificationsService: any;
  @service declare intl: IntlService;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare intercomEventService: any;
  @service declare permissionsService: $TSFixMe;
  @service declare router: Router;
  @service declare knowledgeHubService: KnowledgeHubService;

  @tracked modalOpen = false;
  @tracked moveToOpen = false;
  @tracked updating = false;
  @tracked showDeleteFolderModal = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get folder() {
    return this.args.folder;
  }

  get routeAfterDelete() {
    return this.args.routeAfterDelete || 'apps.app.knowledge-hub.all-content';
  }

  get isCreateSubFolderDisabled() {
    return this.folder?.atMaxDepth();
  }

  get deleteFolderConfirmBodyLabel() {
    if (this.folder?.isRoot) {
      return this.intl.t('knowledge-hub.folders.folders-menu.deletion-modal.root-folder-body', {
        itemName: this.folder?.name,
      });
    } else {
      return this.intl.t('knowledge-hub.folders.folders-menu.deletion-modal.subfolder.body', {
        itemName: this.folder?.name,
      });
    }
  }

  get defaultOptions() {
    return [
      ...(this.args.position !== 'knowledge-hub-folder-card'
        ? [
            {
              text: this.intl.t('knowledge-hub.folders.folders-menu.create-folder'),
              icon: 'new',
              onSelectItem: this.openModal,
              isDisabled: this.isCreateSubFolderDisabled,
              isDestructive: false,
            },
          ]
        : []),
      {
        text: this.intl.t('knowledge-hub.folders.folders-menu.edit'),
        icon: 'edit',
        onSelectItem: this.openUpdateModal,
        isDestructive: false,
      },
      {
        text: this.intl.t('knowledge-hub.folders.folders-menu.move-to'),
        icon: 'move-folder',
        onSelectItem: this.openMoveTo,
        isDestructive: false,
      },
      {
        text: this.intl.t('knowledge-hub.folders.folders-menu.delete'),
        icon: 'trash',
        isDestructive: true,
        onSelectItem: this.toggleDeleteFolderModal,
      },
    ];
  }
  get syncedDefaultOptions() {
    return [
      {
        text: this.intl.t('knowledge-hub.folders.folders-menu.edit'),
        icon: 'edit',
        onSelectItem: this.openUpdateModal,
        isDestructive: false,
      },
    ];
  }

  get options() {
    return this.folder?.isSynced
      ? [{ items: this.syncedDefaultOptions }]
      : [{ items: this.defaultOptions }];
  }

  get currentAdminHasPermission() {
    return this.permissionsService.currentAdminCan(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
  }

  get currentAdminNeedsPermission() {
    return !this.currentAdminHasPermission;
  }

  get shouldShowAiFolderWarning() {
    if (!this.folder) {
      return false;
    }

    return this.appService.app.canUseConversationExtractionExperiment && this.folder.isAiGenerated;
  }

  get finSettingsPageLink() {
    return this.router.urlFor('apps.app.automation.fin-ai-agent.setup', {
      queryParams: {
        openSectionId: 'conversation-content',
      },
    });
  }

  @action
  async showPermissionsModal() {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      return;
    }
  }

  get isRootFolder() {
    return this.folder?.isRoot;
  }

  @action
  async openModal() {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      return;
    }
    this.updating = false;
    this.modalOpen = true;
  }

  @action
  async openUpdateModal() {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      return;
    }
    this.updating = true;
    this.modalOpen = true;
  }

  @action
  closeModal() {
    this.modalOpen = false;
  }

  @action
  async openMoveTo() {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      return;
    }
    this.updating = true;
    this.moveToOpen = true;
  }

  @action
  closeMoveTo() {
    this.moveToOpen = false;
  }

  @action
  async toggleDeleteFolderModal() {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      return;
    }
    this.showDeleteFolderModal = !this.showDeleteFolderModal;
  }

  @action
  async deleteFolder() {
    if (!this.folder || !this.args.item) {
      return;
    }
    let parentFolder = this.folder?.parentFolder;

    try {
      await this.folder.delete();
      removeItemFromTree(this.args.item);
      this.knowledgeHubService.api?.removeContentWrapper(this.folder.contentWrapperId);
      if (this.args.item.isActive) {
        if (parentFolder) {
          this.router.transitionTo('apps.app.knowledge-hub.folder', parentFolder.id);
        } else {
          this.router.transitionTo(this.routeAfterDelete);
        }
      }

      await this.knowledgeHubService.fetchFoldersOnce(true);
      this.notificationsService.notifyConfirmation(
        this.intl.t('knowledge-hub.folders.folders-menu.deletion-modal.success'),
      );

      this.intercomEventService.trackAnalyticsEvent({
        action: 'deleted',
        object: 'folder',
        place: 'knowledge-hub',
      });
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('knowledge-hub.folders.folders-menu.deletion-modal.error'),
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Folders::OptionMenu': typeof OptionMenu;
    'knowledge-hub/folders/option-menu': typeof OptionMenu;
  }
}
