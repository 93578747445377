/* import __COLOCATED_TEMPLATE__ from './learn-button.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import safeWindowOpen from 'embercom/lib/safe-window-open';

export default class LearnButton extends Component {
  @service declare intercomEventService: $TSFixMe;
  @tracked activeVideoId?: string;

  @action trackDropdownClick(name: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: name,
      place: 'learn-dropdown',
    });
  }

  @action openYoutubeVideo() {
    safeWindowOpen(
      'https://www.youtube.com/watch?v=uD0f9BbpTCQ&list=PLlCIldMZCaFrwsQvjSIRXYS35CqEFKbFX',
      '_blank',
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Overview::LearnButton': typeof LearnButton;
    'knowledge-hub/overview/learn-button': typeof LearnButton;
  }
}
