/* import __COLOCATED_TEMPLATE__ from './content-review.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { type KnowledgeHubEditorConfig } from 'embercom/objects/knowledge-hub/knowledge-hub-editor-config';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';
import { type ContentVersion } from 'embercom/services/knowledge-hub-editor-service';
import { trackedInLocalStorage } from 'ember-tracked-local-storage';
import {
  CAN_MANAGE_KNOWLEDGE_BASE_CONTENT,
  DRAWER_EDITOR_SHOW_SIDE_PANEL,
} from 'embercom/lib/knowledge-hub/constants';
import storage from 'embercom/vendor/intercom/storage';
import type IntlService from 'ember-intl/services/intl';
import { type LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import type Model from '@ember-data/model';
import type ContentReviewRequest from 'embercom/models/content-service/content-review-request';
import { type ReviewableContent } from './content-review/editor';
import { ReviewType } from 'embercom/models/content-service/content-review-request';
import { tracked } from '@glimmer/tracking';
import type KnowledgeHubDrawerEditorService from 'embercom/services/knowledge-hub-drawer-editor-service';
import { Status } from 'embercom/models/data/content-service/content-review-statuses';
import { type Block } from '@intercom/interblocks.ts';
import type ContentSuggestionsService from 'embercom/services/content-suggestions-service';
import { dropTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import RouteRegexes from 'embercom/lib/route-regexes';
import type RouterService from '@ember/routing/router-service';

interface Args {
  suggestedVersion: ContentVersion;
  latestVersion?: ContentVersion;
  activeContent: LocalizedKnowledgeContent & Model;
}

export default class ContentReview extends Component<Args> {
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;
  @service declare intl: IntlService;
  @service declare notificationsService: $TSFixMe;
  @service declare knowledgeHubDrawerEditorService: KnowledgeHubDrawerEditorService;
  @service declare permissionsService: $TSFixMe;
  @service declare contentSuggestionsService: ContentSuggestionsService;
  @service declare router: RouterService;

  @trackedInLocalStorage({
    keyName: DRAWER_EDITOR_SHOW_SIDE_PANEL,
  })
  showSidePanel = true;

  @tracked showEditComposer = false;
  @tracked updatedSuggestionBlocks: Array<Block> = [];

  constructor(owner: any, args: Args) {
    super(owner, args);

    this.showSidePanel = storage.get(DRAWER_EDITOR_SHOW_SIDE_PANEL) ?? true;
  }

  get editorConfig(): KnowledgeHubEditorConfig | undefined {
    return this.args.activeContent && this.args.activeContent.editorConfig
      ? this.args.activeContent.editorConfig
      : undefined;
  }

  get reviewReason() {
    return this.contentReviewRequest().get('reviewReason');
  }

  get reviewRequestCreatedAt() {
    return this.intl.t('fin-ai-agent.knowledge.content-suggestions.created', {
      date: this.intl.formatRelative(new Date(this.contentReviewRequest().get('createdAt'))),
    });
  }

  get isEditReviewType() {
    return this.contentReviewRequest().get('reviewType') === ReviewType.EDIT_CONTENT;
  }

  get reviewRequest() {
    return this.contentReviewRequest();
  }

  get updatingContentReviewRequest() {
    return taskFor(this.performUpdateContentReviewRequest).isRunning;
  }

  get isOnAiInsightsPage() {
    return RouteRegexes.automation.insights.test(this.router.currentRouteName);
  }

  private contentReviewRequest(): ContentReviewRequest & Model {
    return (this.args.activeContent as ReviewableContent).contentReviewRequest;
  }

  @action
  toggleSidePanel(): void {
    this.showSidePanel = !this.showSidePanel;
    this.knowledgeHubEditorService.trackAnalyticsEvent(
      `${this.showSidePanel ? 'show' : 'hide'}_details_side_panel`,
    );
  }

  @action
  setEditMode(show: boolean) {
    this.showEditComposer = show;
  }

  @action
  approveVersion() {
    taskFor(this.performUpdateContentReviewRequest).perform(Status.Approved);
    this.removeContentFromAiInsightsList();
  }

  @action
  rejectVersion() {
    taskFor(this.performUpdateContentReviewRequest).perform(Status.Rejected);
    this.removeContentFromAiInsightsList();
  }

  @action
  updateSuggestionBlocks(blocks: Array<Block>) {
    this.updatedSuggestionBlocks = blocks;
  }

  @dropTask
  *performUpdateContentReviewRequest(status: Status) {
    yield this.updateContentReviewRequest(status);
  }

  private async updateContentReviewRequest(status: Status) {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      return;
    }
    let contentReviewRequest = await this.asyncContentReviewRequest();
    let message = status === Status.Approved ? 'approve' : 'reject';

    if (contentReviewRequest) {
      contentReviewRequest.status = status;
      if (
        this.showEditComposer &&
        status === Status.Approved &&
        this.updatedSuggestionBlocks.length > 0
      ) {
        contentReviewRequest.updatedSuggestionBlocks = this.updatedSuggestionBlocks;
      }
      try {
        await contentReviewRequest.save();
        this.notificationsService.notifyConfirmation(
          this.intl.t(`knowledge-hub.suggestion-review.${message}`),
        );
      } catch (e) {
        this.notifyErrorAndCloseDrawer(
          this.intl.t(`knowledge-hub.suggestion-review.error.${message}`),
          false,
        );
      }
      this.reloadSuggestionsListAndCloseDrawer();
    } else {
      this.notifyErrorAndCloseDrawer();
    }
  }

  private removeContentFromAiInsightsList() {
    if (this.isOnAiInsightsPage) {
      this.contentSuggestionsService.removeSuggestionFromList({
        entity_id: this.args.activeContent.entityId,
        entity_type: this.args.activeContent.entityType,
      });
    }
  }

  private async reloadSuggestionsListAndCloseDrawer() {
    if (!this.isOnAiInsightsPage) {
      this.contentSuggestionsService.listApi.resetFiltersForSearch();
      await this.contentSuggestionsService.listApi.loadPage();
    }
    await this.knowledgeHubDrawerEditorService.closeDrawer();
  }

  private async asyncContentReviewRequest() {
    let content = this.args.activeContent;
    return (content as ReviewableContent).contentReviewRequest;
  }

  private async notifyErrorAndCloseDrawer(
    message = this.intl.t('knowledge-hub.suggestion-review.not-found'),
    closeDrawer = true,
  ) {
    this.notificationsService.notifyError(message);
    if (closeDrawer) {
      await this.knowledgeHubDrawerEditorService.closeDrawer();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::ContentReview': typeof ContentReview;
    'knowledge-hub/content-editor/content-review': typeof ContentReview;
  }
}
