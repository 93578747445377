/* import __COLOCATED_TEMPLATE__ from './folder-title.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import type Folder from 'embercom/models/content-service/folder';
import { type HighlightedResult } from 'embercom/objects/inbox/knowledge-base/search-result';
import { tracked } from 'tracked-built-ins';

export interface Args {
  folder?: Folder;
  title?: string;
  showIcon?: boolean;
  createFolder?: boolean;
  truncateShort?: boolean;
  truncateLong?: boolean;
  highlightedTitleArray?: HighlightedResult[];
  hideTooltipMargin?: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
  Blocks: {
    default: [];
  };
}

export default class FolderTitle extends Component<Signature> {
  @tracked hasTruncatedText = true;

  get tooltipText() {
    return this.args?.title || this.args.folder?.get('title') || '';
  }
  @action handleMouseOver(event: MouseEvent) {
    this.hasTruncatedText = this.isTitleTruncated(event.target as HTMLElement);
  }

  isTitleTruncated(folder: HTMLElement) {
    let folderTitle = folder?.querySelector<HTMLElement>('[data-folder-title]');
    return folderTitle !== null && folderTitle?.scrollWidth > folderTitle?.offsetWidth;
  }

  get defaultFolderIcon() {
    return this.args.folder?.get('isAiGenerated') ? 'ai' : 'folder';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Folders::FolderTitle': typeof FolderTitle;
  }
}
