/* import __COLOCATED_TEMPLATE__ from './all-sources.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { type OverviewSectionArgs } from '../../overview';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type AiAgentSetupService from 'embercom/services/ai-agent-setup-service';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import type { SyncSourceWrapperResponse } from 'embercom/services/knowledge-hub-service';

class AllSources extends Component<OverviewSectionArgs> {
  @service declare router: RouterService;
  @service declare appService: any;
  @service declare aiAgentSetupService: AiAgentSetupService;
  @service declare knowledgeHubService: KnowledgeHubService;
  get contentCount() {
    return this.aiAgentSetupService.allContentCount || 0;
  }

  get isSyncing() {
    return Object.keys(this.args.knowledgeUsageSummary).any((key: string) =>
      this.args.knowledgeUsageSummary[parseInt(key, 10)].sync_sources.some(
        (source: SyncSourceWrapperResponse) => source.status === 'syncing',
      ),
    );
  }

  get subheadingTranslationKey() {
    return this.isSyncing
      ? 'knowledge-hub.overview.sections.all-sources.syncing'
      : 'knowledge-hub.overview.sections.all-sources.subheading';
  }

  get rows() {
    return [];
  }

  @action
  onSectionButtonClick() {
    this.router.transitionTo('apps.app.knowledge-hub.all-content', this.appService.app.id);
  }
}
export default AllSources;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Overview::Sections::AllSources': typeof AllSources;
    'knowledge-hub/overview/sections/all-sources': typeof AllSources;
  }
}
