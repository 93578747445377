/* import __COLOCATED_TEMPLATE__ from './suggestion-editor.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */

import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { Block } from '@intercom/interblocks.ts';
import type ContentReviewRequest from 'embercom/models/content-service/content-review-request';
import type { LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import type Model from '@ember-data/model';

interface SuggestionEditorArgs {
  jsonBlocks: Block[] | undefined;
  reviewRequests: ContentReviewRequest[];
  activeContent: LocalizedKnowledgeContent & Model;
}

type BlockGroup = {
  id: string;
  isEdited: boolean;
  blocksBefore?: Block[];
  blocks: Block[];
  indices: [number, number];
  reviewReason?: string;
};

export default class SuggestionEditor extends Component<SuggestionEditorArgs> {
  @tracked showPreviousVersion = false;
  @tracked blockGroups: BlockGroup[] = [];

  constructor(owner: any, args: SuggestionEditorArgs) {
    super(owner, args);
    this.blockGroups = this.createBlockGroups(this.args.jsonBlocks || [], this.args.reviewRequests);
  }

  @action
  scrollToFirstEditedBlockGroup() {
    if (this.editedBlockGroups.length > 0) {
      this.scrollToBlockGroup(this.editedBlockGroups[0]);
    }
  }

  scrollToBlockGroup(blockGroup: BlockGroup) {
    let blockGroupElement = document.getElementById(blockGroup.id);
    if (blockGroupElement) {
      blockGroupElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  get editedBlockGroups(): BlockGroup[] {
    return this.blockGroups.filter((group) => group.isEdited);
  }

  get uneditedBlockGroups(): BlockGroup[] {
    return this.blockGroups.filter((group) => !group.isEdited);
  }

  get suggestionsToEditBlockGroups(): BlockGroup[] {
    return this.blockGroups;
  }

  @action
  toggleShowPreviousVersion() {
    this.showPreviousVersion = !this.showPreviousVersion;
  }

  private createBlockGroups(
    jsonBlocks: Block[],
    reviewRequests: ContentReviewRequest[],
  ): BlockGroup[] {
    let editedBlockCounter = 0;
    let uneditedBlockCounter = 0;
    let blockGroups: BlockGroup[] = [];
    let currentIndex = 0;

    let suggestionsWithReasons = reviewRequests
      .map((request) => ({
        suggestion: request.get('suggestionToEdit'),
        reviewReason: request.get('reviewReason'),
      }))
      .filter(({ suggestion }) => !isEmpty(suggestion));

    // Sort suggestions by start index to process them in order
    let sortedSuggestions = [...suggestionsWithReasons].sort(
      (a, b) => a.suggestion!.indices[0] - b.suggestion!.indices[0],
    );

    for (let { suggestion, reviewReason } of sortedSuggestions) {
      let [startIndex, endIndex] = suggestion!.indices;

      // Add unedited blocks before the suggestion if any
      if (currentIndex < startIndex) {
        let blocksForGroup = jsonBlocks.slice(currentIndex, startIndex);

        blockGroups.push({
          id: `unedited-block-group-${uneditedBlockCounter++}`,
          isEdited: false,
          blocks: blocksForGroup,
          blocksBefore: blocksForGroup,
          indices: [currentIndex, startIndex - 1],
        });
      }

      let blocksBefore = jsonBlocks.slice(startIndex, endIndex + 1);

      // Add the edited blocks from the suggestion
      blockGroups.push({
        isEdited: true,
        id: `edited-block-group-${editedBlockCounter++}`,
        blocks: suggestion!.editedBlocks,
        blocksBefore,
        indices: [startIndex, endIndex],
        reviewReason,
      });

      currentIndex = endIndex + 1;
    }

    // Add any remaining unedited blocks after the last suggestion
    if (currentIndex < jsonBlocks.length) {
      blockGroups.push({
        isEdited: false,
        id: `unedited-block-group-${uneditedBlockCounter++}`,
        blocks: jsonBlocks.slice(currentIndex),
        indices: [currentIndex, jsonBlocks.length - 1],
      });
    }
    return blockGroups;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::SuggestionEditor': typeof SuggestionEditor;
  }
}
