/* import __COLOCATED_TEMPLATE__ from './knowledge-integration.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import type IntlService from 'ember-intl/services/intl';
import { SourceType, SyncBehaviorType } from 'embercom/models/import-service/import-source';
import type ImportSource from 'embercom/models/import-service/import-source';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';

interface Args {
  modal: any;
  title?: string;
  onModalClose: () => void;
  connectionType: string;
}

const CONNECTION_MAP: Record<string, string> = {
  'salesforce-src': 'salesforce',
};

export default class KnowledgeIntegrationSyncModal extends Component<Args> {
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare intercomEventService: any;

  @tracked importSource: ImportSource | undefined;
  @tracked syncBehavior: SyncBehaviorType = SyncBehaviorType.Sync;
  @tracked isConnecting = false;

  get connectionType(): string {
    return CONNECTION_MAP[this.args.connectionType];
  }

  get connectionIcon() {
    switch (this.connectionType) {
      case 'salesforce':
        return 'salesforce';
      default:
        return undefined;
    }
  }

  get connectionImageUrl() {
    return assetUrl(`/assets/images/knowledge-hub/${this.connectionType}-connection@2x.png`);
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  @action
  async initiateConnection() {
    if (this.isConnecting) {
      return;
    }

    this.isConnecting = true;
    try {
      this.args.onModalClose();
      await this.createConnection();
    } catch (error) {
      this.isConnecting = false;
      throw error;
    }
  }

  @action
  async createConnection() {
    let importSourceParams = {
      sourceType: SourceType.Salesforce,
    };
    this.importSource = await this.knowledgeHubService.createImportSource(importSourceParams);
    this.knowledgeHubService.startImport(this.importSource.id, this.syncBehavior);

    let notificationMessage =
      this.syncBehavior === SyncBehaviorType.Sync
        ? this.intl.t(`knowledge-hub.sync-modal.${this.connectionType}.sync-started`)
        : this.intl.t(`knowledge-hub.sync-modal.${this.connectionType}.import-started`);

    this.knowledgeHubService.notifySourceStateChangeConfirmation(
      notificationMessage,
      this.importSource.id,
    );
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      context: 'import-content',
      object: `import-content.${this.connectionType}`,
      section: `knowledge-hub.new-content.${this.connectionType}`,
      place: 'knowledge-hub.new-content',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::SetupModal::SyncModal::KnowledgeIntegration': typeof KnowledgeIntegrationSyncModal;
    'knowledge-hub/setup-modal/sync-modal/knowledge-integration': typeof KnowledgeIntegrationSyncModal;
  }
}
