/* import __COLOCATED_TEMPLATE__ from './box.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import type ImportSource from 'embercom/models/import-service/import-source';
import { type SyncSteps } from 'embercom/components/knowledge-hub/filterable-list';
import { SyncBehaviorType } from 'embercom/models/import-service/import-source';

interface Args {
  modal: any;
  title?: string;
  onNavigationBack?: () => void;
  onNavigationNext?: (viewId?: string) => void;
  showBackButton?: boolean;
  onModalClose: () => void;
  setSyncStep: (step: SyncSteps) => void;
}

export default class BoxSyncModal extends Component<Args> {
  @service declare knowledgeHubService: KnowledgeHubService;

  @tracked importSource: ImportSource | undefined;
  @tracked syncBehavior: SyncBehaviorType = SyncBehaviorType.Import;

  get isLoadingOauth() {
    return true;
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get syncDisabled() {
    return !this.importSource;
  }

  @action async startSync() {
    // TODO: after the oauth flow and file selection is complete, we need to start the import flow
  }

  @action async cancelSync() {
    if (this.importSource) {
      await this.importSource.remove({ notify: false });
    }
    this.args.onModalClose();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::SetupModal::SyncModal::Box': typeof BoxSyncModal;
    'knowledge-hub/setup-modal/sync-modal/box': typeof BoxSyncModal;
  }
}
