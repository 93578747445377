/* import __COLOCATED_TEMPLATE__ from './zendesk.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { TaskGenerator } from 'ember-concurrency';
import ajax from 'embercom/lib/ajax';
import type ArticleSyncSetting from 'embercom/models/articles/article-sync-setting';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { isEmpty } from 'underscore';
import { taskFor } from 'ember-concurrency-ts';
import { task } from 'ember-concurrency-decorators';
import { SyncBehaviorType } from 'embercom/models/import-service/import-source';

interface Args {
  modal: any;
  title?: string;
  onNavigationBack?: () => void;
  onNavigationNext?: (viewId?: string) => void;
  showBackButton?: boolean;
  onModalClose: () => void;
}

export default class ZendeskSyncModal extends Component<Args> {
  @service store: any;
  @service realTimeEventService: any;
  @service openCenteredWindowService: any;
  @service notificationsService: any;
  @service intl: any;
  @service helpCenterService: any;
  @service appService: any;
  @tracked url = '';
  @tracked selectedHelpCenter;
  @tracked sync: boolean;
  @tracked existingImport = false;
  @tracked syncSettings: ArticleSyncSetting[] = [];
  @service declare intercomEventService: any;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.realTimeEventService.on('ImportStarted', this, 'handleStartedImport');
    this.selectedHelpCenter = this.helpCenterService.site;
    taskFor(this.getRunningImport).perform();
    taskFor(this.setSyncSettings).perform();

    this.sync = this.appService.app.canUseStandalone;
  }

  willDestroy() {
    super.willDestroy();
    this.realTimeEventService.off('ImportStarted', this, 'handleStartedImport');
  }

  @task({ drop: true })
  *setSyncSettings() {
    this.syncSettings = yield this.store.findAll('articles/article-sync-setting');
  }

  get activeSyncSettings() {
    return this.syncSettings.find(
      (setting: ArticleSyncSetting) =>
        setting.provider === 'zendesk' && setting.status === 'active',
    );
  }

  @action
  removeExistingSync() {
    if (this.activeSyncSettings) {
      taskFor(this.pauseActiveSync).perform();
    }
  }

  get isRemovingSync() {
    return taskFor(this.pauseActiveSync).isRunning;
  }

  @task({ drop: true })
  *pauseActiveSync() {
    yield this.activeSyncSettings?.pause();
  }

  get hasActiveApiImport() {
    return this.existingImport;
  }

  @task({ drop: true })
  *getRunningImport(): TaskGenerator<void> {
    this.existingImport = yield ajax({
      url: '/ember/article_import_jobs/current_import',
      type: 'GET',
      data: { app_id: this.app.id },
    });
  }

  get shouldShowCleanUpModal() {
    return !this.app.canUseMultipleZendeskBrandArticleSync && this.activeSyncSettings;
  }

  get app() {
    return this.appService.app;
  }

  get helpCenters() {
    return this.helpCenterService.sites;
  }

  get helpCenterOptions() {
    return this.helpCenters?.map((site: HelpCenterSite) => {
      return {
        text: site.name,
        value: site.id,
      };
    });
  }

  get isUrlInvalid() {
    let normalizedUrl = this.normalizeZendeskURL(this.url);

    if (!normalizedUrl) {
      return false;
    }

    try {
      let parsedUrl = new URL(normalizedUrl);
      return !parsedUrl.hostname.match(/.+\.zendesk\.com$/);
    } catch (e) {
      return true;
    }
  }

  get errorMessage() {
    if (this.isUrlInvalid) {
      return this.intl.t('knowledge-hub.sync-modal.zendesk.error-message');
    }

    if (this.isExistingActiveZendeskSync) {
      return this.intl.t('knowledge-hub.sync-modal.zendesk.already-synced-subdomain');
    }
  }

  get isExistingActiveZendeskSync() {
    return !!this.syncSettings.find((setting: ArticleSyncSetting) => {
      return (
        setting.provider === 'zendesk' &&
        setting.status === 'active' &&
        setting.siteUrl === this.normalizeZendeskURL(this.url)
      );
    });
  }

  get syncBehavior() {
    return this.sync ? SyncBehaviorType.Sync : SyncBehaviorType.Import;
  }

  handleStartedImport() {
    let notificationMessage = this.sync
      ? this.intl.t('knowledge-hub.sync-modal.zendesk.sync-started')
      : this.intl.t('knowledge-hub.sync-modal.zendesk.import-started');
    this.notificationsService.notifyConfirmation(notificationMessage);
    this.args.onModalClose();
  }

  @action switchHelpCenter(value: string) {
    this.selectedHelpCenter = this.helpCenters.find(
      (helpCenter: HelpCenterSite) => helpCenter.id === value,
    );
  }

  @action
  async startSync() {
    let url = this.normalizeZendeskURL(this.url);
    if (!url) {
      return;
    }
    let oauthState: any = await this.createOauthState(url);
    let ZdUrl = await this.zendeskOAuthURL(oauthState.id, url);
    let window = this.openCenteredWindowService.setup(ZdUrl, 800, 1280);
    if (window) {
      window.focus();
      await this.waitForWindowClose(window);
    }
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      context: 'import-content',
      object: `import-content.zendesk`,
      section: 'knowledge-hub.new-content.zendesk',
      place: 'knowledge-hub.new-content',
    });
  }

  normalizeZendeskURL(url: string) {
    url = url?.trim();

    if (isEmpty(url)) {
      return;
    }

    if (url.match(/^http:\/\//)) {
      url = url.substring(7);
    }

    if (url.match(/^https/) === null) {
      url = `https://${url}`;
    }

    let HELP_CENTER_REGEX = /\/hc/;
    // This conditional will strip out the help center centric part of the URL
    if (url.match(HELP_CENTER_REGEX)) {
      let index = url.match(HELP_CENTER_REGEX)?.index;
      url = url.substring(0, index);
    }

    return url;
  }

  async createOauthState(url: string) {
    let oauthState = this.store.createRecord('articles/oauth-state', {
      siteUrl: url,
      helpCenterId: this.selectedHelpCenter.id,
    });
    return oauthState.save();
  }

  async zendeskOAuthURL(oauthStateId: string, url: string) {
    let stateJson = JSON.stringify({
      oauth_state_id: oauthStateId,
      mode: this.sync ? 'sync' : 'import',
    });

    let redirectUri = this.selectedHelpCenter.zendeskImporterRedirectUri;
    let clientId = this.selectedHelpCenter.zendeskImporterClientId;

    let queryParams = encodeURI(
      `response_type=code&redirect_uri=${redirectUri}&client_id=${clientId}&scope=hc:read&state=${stateJson}`,
    );

    return `${url}/oauth/authorizations/new?${queryParams}`;
  }

  private waitForWindowClose(window: any): Promise<void> {
    return new Promise((resolve) => {
      let checkInterval = setInterval(() => {
        if (window.closed) {
          clearInterval(checkInterval);
          resolve();
        }
      }, 200);
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::SetupModal::SyncModal::Zendesk': typeof ZendeskSyncModal;
    'knowledge-hub/setup-modal/sync-modal/zendesk': typeof ZendeskSyncModal;
  }
}
